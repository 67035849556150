import { ComponentType } from 'react';
import { GridCellProps } from '@progress/kendo-react-grid';
import { MIN_COLUMN_WIDTH, COLUMN_VISIBLE } from '../../../constants';
import { IGridColumn } from '../../common/interfaces';

export const getPricesColumns = (): IGridColumn[] => {
    return [
        {
            field: 'name',
            title: 'sites.entityName',
            filter: 'text',
            minWidth: MIN_COLUMN_WIDTH,
            show: COLUMN_VISIBLE,
        },
        {
            field: 'last_update_of_prices',
            title: 'prices.lastUpdated',
            filter: 'date',
            minWidth: MIN_COLUMN_WIDTH,
            show: COLUMN_VISIBLE,
        },
    ];
};

export const getSitePricesColumns = (
    nowColumnVisibility?: boolean,
    customCells?: Map<string, ComponentType<GridCellProps>>
): IGridColumn[] => {
    return [
        {
            field: 'product_name',
            title: 'prices.productName',
            minWidth: MIN_COLUMN_WIDTH,
            show: COLUMN_VISIBLE,
            sortable: false,
            editable: false,
        },
        {
            field: 'unit_price',
            title: 'prices.unitPrice',
            minWidth: MIN_COLUMN_WIDTH,
            show: COLUMN_VISIBLE,
            sortable: false,
            editable: false,
        },
        {
            field: 'new_unit_price',
            title: 'prices.newUnitPrice',
            minWidth: MIN_COLUMN_WIDTH,
            show: COLUMN_VISIBLE,
            sortable: false,
            cell: customCells?.get('newUnitPrice'),
        },
        nowColumnVisibility
            ? {
                  field: 'now',
                  title: 'prices.now',
                  minWidth: MIN_COLUMN_WIDTH,
                  width: MIN_COLUMN_WIDTH,
                  sortable: false,
                  show: COLUMN_VISIBLE,
                  cell: customCells?.get('now'),
              }
            : {},
        {
            field: 'activation_timestamp',
            title: 'prices.activationDateAndTime',
            minWidth: MIN_COLUMN_WIDTH,
            show: COLUMN_VISIBLE,
            sortable: false,
            cell: customCells?.get('activationDateAndTime'),
        },
    ];
};

export const getRelationsColumns = (
    customCells: Map<string, ComponentType<GridCellProps>>
): IGridColumn[] => {
    return [
        {
            field: 'product_name',
            title: 'prices.productName',
            minWidth: MIN_COLUMN_WIDTH,
            show: COLUMN_VISIBLE,
            sortable: false,
            editable: false,
        },
        {
            field: 'related_products',
            title: 'prices.assignedProducts',
            minWidth: MIN_COLUMN_WIDTH,
            show: COLUMN_VISIBLE,
            sortable: false,
            cell: customCells?.get('assignedProducts'),
        },
    ];
};
