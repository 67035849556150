import { Fragment, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FormControl, Grid, Typography } from '@mui/material';
import { UploadOnRemoveEvent, UploadOnStatusChangeEvent } from '@progress/kendo-react-upload';

import { IMAGE_EXTENSIONS, LOGO_METADATA, URLS } from '../../../../constants';
import { FormControlLabelComponent, FormInput, UploadFiles } from '../../../common';
import { InputType, InputValue } from '../../../common/interfaces';
import {
    createDeleteUrl,
    createUploadUrl,
    getDefaultFilesInfo,
    translateTerm,
} from '../../../../helpers';
import { GeneralTabProps, ModuleField, ModuleFormValues } from '../../interfaces';

interface ModuleConfigurationTabInterface extends GeneralTabProps<ModuleFormValues> {
    moduleFields: ModuleField[] | [];
    selectedMandatorId: string;
    componentName: string;
}

const ModuleConfigurationTab = ({
    moduleFields,
    formProps,
    selectedMandatorId,
    componentName,
    isDisabled,
}: ModuleConfigurationTabInterface) => {
    const { t } = useTranslation();
    const { values, setFieldValue, handleChange, handleBlur } = formProps;

    const getFieldLabel = useCallback(
        (fieldName: string) => {
            return translateTerm(`marketplace.fields.${fieldName}`, fieldName, t);
        },
        [t]
    );

    const uploadSaveUrl = createUploadUrl(
        `${URLS.ManageFiles}`,
        LOGO_METADATA,
        componentName,
        selectedMandatorId
    );
    const removeUrl = useCallback(
        (field_name: string) => {
            return createDeleteUrl(`${URLS.ManageFiles}`, values[field_name] as string);
        },
        [values]
    );

    const onFileChange = (data: UploadOnStatusChangeEvent, fieldName: string) => {
        if (data.response) {
            const backendResponse = data.response.response;
            setFieldValue(fieldName, backendResponse?.data[0]?.access_file_path);
        }
    };
    const onFileRemoval = (data: UploadOnRemoveEvent, fieldName: string) => {
        const response = data?.response?.response;
        if (response && response.data?.length === 1) {
            setFieldValue(fieldName, '');
        }
    };

    const generateFormField = (field: ModuleField) => {
        const { field_type, field_name } = field;

        const fieldValue =
            field_type !== 'number' ? (values[field_name] ?? '') : values[field_name];

        switch (field_type) {
            case 'boolean':
                return (
                    <FormControl className="formControl switchInput" variant="outlined">
                        <Typography variant="h3">{getFieldLabel(field_name)}</Typography>
                        <FormControlLabelComponent
                            active={JSON.parse(fieldValue as string)}
                            onChange={(event) => {
                                event.target.value = (
                                    event.target.value.length > 0 ? true : false
                                ).toString();
                                handleChange(event);
                            }}
                            size="medium"
                            color="success"
                            name={field_name}
                            control={'switch'}
                            disabled={isDisabled}
                        />
                    </FormControl>
                );
            case 'file':
                return (
                    <FormControl className="formControl" sx={{ maxWidth: '25rem' }}>
                        <Typography variant="h3">{getFieldLabel(field_name)}</Typography>
                        <UploadFiles
                            saveUrl={uploadSaveUrl}
                            removeUrl={removeUrl(field_name)}
                            fileMetaData={LOGO_METADATA}
                            allowedExtensions={IMAGE_EXTENSIONS}
                            withCredentials={false}
                            isDisabled={isDisabled}
                            onChange={(event: UploadOnStatusChangeEvent) => {
                                onFileChange(event, field_name);
                            }}
                            onRemove={(event: UploadOnRemoveEvent) =>
                                onFileRemoval(event, field_name)
                            }
                            defaultFiles={
                                values[field_name]
                                    ? getDefaultFilesInfo(values[field_name] as string)
                                    : []
                            }
                        />
                    </FormControl>
                );
            default:
                return (
                    <FormInput
                        label={getFieldLabel(field_name)}
                        fieldName={field_name}
                        type={field.field_type as InputType}
                        value={fieldValue as InputValue}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        disabled={isDisabled}
                    />
                );
        }
    };

    return (
        <Grid
            item
            xs={12}
            sm={8}
            md={8}
            lg={6}
            xl={4}
            sx={{ display: 'flex', flexDirection: 'column' }}>
            {moduleFields.map((field: ModuleField, index: number) => (
                <Fragment key={index}>{generateFormField(field)}</Fragment>
            ))}
        </Grid>
    );
};
export default ModuleConfigurationTab;
