import { Box } from '@mui/material';

import FilterButton from './FilterButton';
import FilterDrawer from './FilterDrawer';
import AppliedFilters from './AppliedFilters';

interface FilterComponentProps {
    openedDrawer: boolean;
    filterInputs: Map<string, unknown>;
    appliedFilters: object;
    defaultFilters: object;
    applyFilters: () => void;
    resetFilters: () => void;
    updateFilters: (event: Record<string, any>) => void;
    toggleFilterDrawer: (newState: boolean) => void;
    filterButtonClassName?: string;
}

/**
 * Renders the FilterComponent which contains a FilterButton and a FilterDrawer.
 *
 * @param {FilterComponentProps} props - The props object containing the following properties:
 *   - openedDrawer: a boolean indicating whether the FilterDrawer is currently open.
 *   - filterInputs: a Map of filter inputs.
 *   - appliedFilters: an object containing the applied filters.
 *   - defaultFilters: an object containing the default filters.
 *   - applyFilters: a function to apply the filters.
 *   - resetFilters: a function to reset the filters.
 *   - updateFilters: a function to update the filters.
 *   - toggleFilterDrawer: a function to toggle the FilterDrawer.
 *   - filterButtonClassName: className for filter box
 * @return {JSX.Element} The rendered FilterComponent.
 */
const FilterComponent = ({
    openedDrawer,
    filterInputs,
    appliedFilters,
    defaultFilters,
    applyFilters,
    resetFilters,
    updateFilters,
    toggleFilterDrawer,
    filterButtonClassName,
}: FilterComponentProps): JSX.Element => {
    const openDrawer = () => {
        return toggleFilterDrawer(true);
    };

    return (
        <>
            <Box
                sx={{ display: 'flex', flexDirection: 'row-reverse' }}
                className={filterButtonClassName}>
                <FilterButton onClick={openDrawer} />
                {openedDrawer && (
                    <FilterDrawer
                        filterInputs={filterInputs}
                        applyFilters={applyFilters}
                        resetFilters={resetFilters}
                        closeDrawer={toggleFilterDrawer}
                    />
                )}
            </Box>
            <AppliedFilters
                filters={appliedFilters}
                updateFilters={updateFilters}
                defaultFilters={defaultFilters}
            />
        </>
    );
};

export default FilterComponent;
