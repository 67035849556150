import { memo } from 'react';
import { Grid, Typography, Divider, Box } from '@mui/material';
import { Add } from '@mui/icons-material';
import TooltipButton from '../buttons/TooltipButton';
import { Actions } from '../../../constants';

interface ComponentHeaderProps {
    handleClick?: () => void;
    title: string;
    addRight?: boolean | null;
    customHeaderButton?: JSX.Element;
    customAdditionalHeaderButton?: JSX.Element;
}

/**
 * Component used to display the title and the action buttons of a page
 *
 * @param {ComponentHeaderProps} props - The properties of the component that contain:
 * @param {() => void} handleClick - Function for handling click events
 * @param {string} title - The title to be displayed
 * @param {boolean | null} addRight - Flag to determine if user has right to add
 * @param {JSX.Element} customHeaderButton - Custom header button element
 * @param {JSX.Element} customAdditionalHeaderButton - Custom additional header button element
 * @return {JSX.Element} The rendered component header
 */
const ComponentHeader = memo(
    ({
        handleClick,
        title,
        addRight,
        customHeaderButton,
        customAdditionalHeaderButton,
    }: ComponentHeaderProps): JSX.Element => {
        return (
            <>
                <Grid container justifyContent="space-between" mb={2}>
                    <Typography variant="h1" color="secondary">
                        {title}
                    </Typography>
                    <Box sx={{ display: 'flex' }} gap={1}>
                        {addRight && (
                            <TooltipButton
                                buttonVariant="contained"
                                title={`general.labels.${Actions.Add}`}
                                icon={<Add />}
                                onClick={handleClick}
                            />
                        )}
                        {customHeaderButton}
                        {customAdditionalHeaderButton}
                    </Box>
                </Grid>

                <Divider sx={{ mb: 5 }} />
            </>
        );
    }
);

export default ComponentHeader;
