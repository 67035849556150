import moment from 'moment';
import { SelectionRange } from '@progress/kendo-react-dateinputs';

export const TIME_STEPS = { minute: 30 };
export const DATE_FORMAT = 'dd/MM/yyyy';
export const MOMENT_DATE_FORMAT = 'DD/MM/yyyy';
export const TIME_FORMAT = 'HH:mm';
export const DATE_TIME_FORMAT = 'dd/MM/yyyy HH:mm';
export const MOMENT_DATE_TIME_FORMAT = 'DD/MM/yyyy HH:mm';

export const YEAR_FORMAT = 'yyyy';
export const DAY_FORMAT = 'dd';
export const MONTH_FORMAT = 'MM';

export const HOUR_FORMAT = 'HH';
export const MINUTES_FORMAT = 'mm';

export const DATE_RANGE_INITIAL_FILTER: SelectionRange = { start: null, end: null };

export const TODAY_DATE = moment().toDate();
export const TODAY_ISO_STRING = moment().toDate().toISOString();
export const TODAY_DATE_TIME = new Date(
    moment({ hour: 0, minute: 0 }).add(1, 'days').toDate()
).toISOString();
export const YESTERDAY_DATE_TIME = new Date(
    moment({ hour: 0, minute: 0 }).subtract(1, 'days').toDate()
).toISOString();
export const LAST_WEEK_DATE_TIME = new Date(
    moment(TODAY_DATE_TIME).subtract(8, 'days').toDate()
).toISOString();

export const DEFAULT_DATE = moment().format('YYYY-MM-DD');

export const DATE_RANGES = [
    {
        name: 'general.dates.today',
        value: {
            start: moment({ hour: 0, minute: 0 }).toDate(),
            end: moment({ hour: 0, minute: 0 }).toDate(),
        },
    },
    {
        name: 'general.dates.thisWeek',
        value: {
            start: moment().startOf('isoWeek').toDate(),
            end: moment().endOf('isoWeek').toDate(),
        },
    },
    {
        name: 'general.dates.lastWeek',
        value: {
            start: moment().startOf('isoWeek').subtract(7, 'days').toDate(),
            end: moment().endOf('isoWeek').subtract(7, 'days').toDate(),
        },
    },
    {
        name: 'general.dates.thisMonth',
        value: {
            start: moment().startOf('month').toDate(),
            end: moment().endOf('month').toDate(),
        },
    },
    {
        name: 'general.dates.lastMonth',
        value: {
            start: moment().subtract(1, 'months').startOf('month').toDate(),
            end: moment().subtract(1, 'months').endOf('month').toDate(),
        },
    },
    {
        name: 'general.dates.thisYear',
        value: {
            start: moment().startOf('year').toDate(),
            end: moment().endOf('year').toDate(),
        },
    },
    {
        name: 'general.dates.lastYear',
        value: {
            start: moment().subtract(1, 'years').startOf('year').toDate(),
            end: moment().subtract(1, 'years').endOf('year').toDate(),
        },
    },
];

export const DEVICE_LOGS_DATE_RANGES = [DATE_RANGES[0], DATE_RANGES[1]];

export const TODAY_RANGE_DATE = DATE_RANGES[0].value;
export const THIS_WEEK_DATE = DATE_RANGES[1].value;
export const LAST_WEEK_DATE = DATE_RANGES[2].value;

export const daysArray = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday',
];
