import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { FreeParkingConfigurationsStateTypes, ExtendedGetReqProps } from '../../interfaces';
import { buildQueryParams } from '../../../../helpers';
import { Interceptor } from '../../../common';
import { INITIAL_DATA_STATE, URLS } from '../../../../constants';
import { FreeParkingConfigurationUpdateValue } from '../../interfaces/parking/freeParking';

const initialState: FreeParkingConfigurationsStateTypes = {
    configurations: INITIAL_DATA_STATE,
    configuration: null,
};

// create a thunk for getting configurations
export const getConfigurations = createAsyncThunk(
    'freeParking/configurations/list',
    async ({ mandator_id, data_state }: ExtendedGetReqProps, { rejectWithValue }) => {
        try {
            const queryParams = data_state && buildQueryParams(data_state);
            const response = await Interceptor().get(
                `${URLS.FreeParkingBFF}/named-configurations`,
                {
                    params: { mandator_id, ...queryParams },
                }
            );
            return response.data;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

// create a thunk for getting a configuration by id
export const getConfigurationById = createAsyncThunk(
    'freeParking/configurations/configuration',
    async (configuration_id: string, { rejectWithValue }) => {
        try {
            const response = await Interceptor().get(
                `${URLS.FreeParkingBFF}/named-configurations/${configuration_id}`
            );
            return response.data;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

export const updateConfiguration = createAsyncThunk(
    'freeParking/configurations/update',
    async (configuration: FreeParkingConfigurationUpdateValue, { rejectWithValue }) => {
        try {
            const response = await Interceptor().patch(
                `${URLS.FreeParkingBFF}/named-configurations/${configuration.id}`,
                { configuration }
            );
            return response.data;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

export const createConfiguration = createAsyncThunk(
    'freeParking/configurations/add',
    async (configuration: FreeParkingConfigurationUpdateValue, { rejectWithValue }) => {
        try {
            const response = await Interceptor().post(
                `${URLS.FreeParkingBFF}/named-configurations`,
                { configuration }
            );
            return response.data;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

//create thunk for deleting a freeParking configuration
export const deleteConfiguration = createAsyncThunk(
    'freeParking/configurations/delete',
    async (configuration_id: string, { rejectWithValue }) => {
        try {
            return await Interceptor().delete(
                `${URLS.FreeParkingBFF}/named-configurations/${configuration_id}`
            );
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

//reducers
const freeParkingConfigurationsSlice = createSlice({
    name: 'freeParking',
    initialState,
    reducers: {
        resetConfigurations: (state) => {
            state.configurations = initialState.configurations;
            state.configuration = initialState.configuration;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getConfigurations.fulfilled, (state, { payload }) => {
            state.configurations.data = [...payload.data];
            state.configurations.total = payload.meta.total;
        });
        builder.addCase(getConfigurationById.fulfilled, (state, { payload }) => {
            state.configuration = payload.data[0];
        });
    },
});

export const { resetConfigurations } = freeParkingConfigurationsSlice.actions;
export default freeParkingConfigurationsSlice.reducer;
