import { useTranslation } from 'react-i18next';
import { FilterAlt } from '@mui/icons-material';
import { Box, IconButton, Tooltip } from '@mui/material';

interface FilterButtonProps {
    onClick: () => void;
}

/**
 * Component used to render filter button that opens filters drawer
 *
 * @param {FilterButtonProps} props.onClick - Function to handle click event
 * @return {JSX.Element} The JSX element representing the filter button
 */
const FilterButton = ({ onClick }: FilterButtonProps): JSX.Element => {
    const { t } = useTranslation();
    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Tooltip title={t('general.labels.filter')}>
                <IconButton className="filter-button" onClick={onClick}>
                    <FilterAlt />
                </IconButton>
            </Tooltip>
        </Box>
    );
};

export default FilterButton;
