export const servicesModulesMapping: { [key: string]: string } = {
    'services.manageDigitalReceipts.digitalReceipt': 'DigitalReceipts',
    'services.manageDigitalReceipts.siteAssignments': 'DigitalReceipts',
    'services.manageDigitalReceipts.emailTemplates': 'DigitalReceipts',
    'services.manageFreeParking.freeParking': 'FreeParking',
    'services.manageFreeParking.siteAssignments': 'FreeParking',
    'services.manageFleets.fleets': 'FleetNet',
    'services.manageFleets.fleetnetSites': 'FleetNet',
    'services.manageFleets.fleetnetDevices': 'FleetNet',
    'services.manageTwint.twint': 'TWINT',
};
