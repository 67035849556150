import { SetStateAction } from 'react';
import { Dispatch, createSlice } from '@reduxjs/toolkit';
import { UploadFileInfo } from '@progress/kendo-react-upload';
import { EditorView } from '@progress/kendo-editor-common';

import { FormikValues } from 'formik';
import * as Yup from 'yup';

import { AssignmentPickerData } from '../../pages/interfaces';
import { ControlRadioButton, ImageMetaData } from '../interfaces';

interface ModalState {
    showModal: boolean;
    title: string;
    type: string;
    numberOfSites: number;
    message?: string;
    component?: string;
    parent?: string;
    onLeave?: () => void;
    labelName?: string;
    fieldName?: string;
    extendedComponent?: boolean;
    formInitialValues?: FormikValues;
    statusControls?: ControlRadioButton[];
    onSubmit?: (fields: FormikValues | string) => void | Promise<any>;
    assignmentPickerData?: AssignmentPickerData;
    confirmCustomLabel?: string;
    cancelCustomLabel?: string;
    isCancelButtonHidden?: boolean;
    additionalProps?: any;
    customMessage?: JSX.Element;
    selectOptions?: Record<string, string>[];
    formValidations?: Yup.ObjectSchema<FormikValues>;
    selectedSites?: string[];
    getSitesAssignmentsData?: () => void;
    customCheckboxLabel?: string;
    setSelectedEntitiesIds?: Dispatch<SetStateAction<any>>;
    saveUrl?: string;
    fileMetadata?: ImageMetaData;
    uploadImageLabel?: string;
    editorView?: EditorView;
    imagePath?: string | null;
}

const initialState: ModalState = {
    showModal: false,
    title: '',
    type: '',
    numberOfSites: 0,
};

const modalSlice = createSlice({
    name: 'modal',
    initialState,
    reducers: {
        displayModal: (state, action) => {
            const {
                showModal,
                title,
                type,
                message,
                component,
                parent,
                onLeave,
                labelName,
                fieldName,
                extendedComponent,
                formInitialValues,
                statusControls,
                onSubmit,
                assignmentPickerData,
                confirmCustomLabel,
                cancelCustomLabel,
                isCancelButtonHidden,
                additionalProps,
                customMessage,
                selectOptions,
                formValidations,
                selectedSites,
                getSitesAssignmentsData,
                numberOfSites,
                customCheckboxLabel,
                setSelectedEntitiesIds,
                saveUrl,
                fileMetadata,
                uploadImageLabel,
                editorView,
                imagePath,
            } = action.payload as ModalState;
            return {
                ...state,
                showModal,
                title,
                type,
                message,
                component,
                parent,
                onLeave,
                labelName,
                fieldName,
                extendedComponent,
                formInitialValues,
                assignmentPickerData,
                statusControls,
                onSubmit,
                confirmCustomLabel,
                cancelCustomLabel,
                isCancelButtonHidden,
                additionalProps,
                customMessage,
                selectOptions,
                formValidations,
                selectedSites,
                getSitesAssignmentsData,
                numberOfSites,
                customCheckboxLabel,
                setSelectedEntitiesIds,
                saveUrl,
                fileMetadata,
                uploadImageLabel,
                editorView,
                imagePath,
            };
        },
    },
});
export const { displayModal } = modalSlice.actions;
export default modalSlice.reducer;
