import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import {
    ConfigurationAssignmentFormValues,
    ConfigurationAssignmentStateTypes,
    ExtendedGetReqProps,
} from '../../../interfaces';
import { buildQueryParams } from '../../../../../helpers';
import { Interceptor } from '../../../../common';
import { INITIAL_DATA_STATE, URLS } from '../../../../../constants';

const initialState: ConfigurationAssignmentStateTypes = {
    siteAssignments: INITIAL_DATA_STATE,
    siteAssignment: null,
};

// create a thunk for getting site assignments
export const getConfigurationAssignments = createAsyncThunk(
    'freeParking/siteAssignments/list',
    async ({ mandator_id, data_state }: ExtendedGetReqProps, { rejectWithValue }) => {
        try {
            const queryParams = data_state && buildQueryParams(data_state);
            const response = await Interceptor().get(`${URLS.FreeParkingBFF}/site-assignment`, {
                params: { mandator_id, ...queryParams },
            });
            return response.data;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

// create a thunk for getting a site assignment by id
export const getConfigurationAssignmentById = createAsyncThunk(
    'freeParking/siteAssignments/siteAssignment',
    async (site_id: string, { rejectWithValue }) => {
        try {
            const response = await Interceptor().get(
                `${URLS.FreeParkingBFF}/site-assignment/${site_id}`
            );
            return response.data;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

//create thunk for assigning a configuration to a site
export const updateConfigurationAssignment = createAsyncThunk(
    'freeParking/siteAssignments/update',
    async (
        { configuration_id, site_id }: ConfigurationAssignmentFormValues,
        { rejectWithValue }
    ) => {
        try {
            const response = await Interceptor().post(`${URLS.FreeParkingBFF}/site-assignment`, {
                configuration_id,
                site_id,
            });
            return response.data;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

//create thunk for deleting an assignment
export const deleteConfigurationAssignment = createAsyncThunk(
    'freeParking/siteAssignments/delete',
    async (site_id: string, { rejectWithValue }) => {
        try {
            return await Interceptor().delete(`${URLS.FreeParkingBFF}/site-assignment/${site_id}`);
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

//reducers
const freeParkingConfigurationAssignmentsSlice = createSlice({
    name: 'freeParkingConfigurationAssignments',
    initialState,
    reducers: {
        resetConfigurationAssignments: (state) => {
            state.siteAssignment = initialState.siteAssignment;
            state.siteAssignments = initialState.siteAssignments;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getConfigurationAssignments.fulfilled, (state, { payload }) => {
            state.siteAssignments.data = [...payload.data];
            state.siteAssignments.total = payload.meta.total;
        });
        builder.addCase(getConfigurationAssignmentById.fulfilled, (state, { payload }) => {
            state.siteAssignment = payload.data[0];
        });
    },
});

export const { resetConfigurationAssignments } = freeParkingConfigurationAssignmentsSlice.actions;
export default freeParkingConfigurationAssignmentsSlice.reducer;
