import { t } from 'i18next';

import {
    configureStore,
    isRejectedWithValue,
    Middleware,
    isFulfilled,
    combineReducers,
    Action,
} from '@reduxjs/toolkit';

import { Operations, SUCCESSFUL_ACTION, ToasterType } from './../constants';
import { showToaster } from '../helpers';
// CORE
import authReducer from './auth/authDuck';

import modalReducer from './common/modal/modalDuck';
import loadingReducer from './common/loading/loadingDuck';
import layoutReducer from './common/layout/layoutDuck';
import commonDataReducer from './common/commonDataDuck';
import userRightsReducer from './common/layout/userRightsDuck';
import payedServicesReducer from './common/layout/payedServicesDuck';

import usersReducer from './pages/users/usersDuck';
import mandatorsReducer from './pages/mandators/mandatorDuck';
import servicesReducer from './pages/services/servicesDuck';
import sitesReducer from './pages/sites/siteDuck';
import productsReducer from './pages/products/productDuck';
import productsAssignmentReducer from './pages/productsAssignment/productAssignmentDuck';
import devicesReducer from './pages/devices/deviceDuck';
import pricesReducer from './pages/prices/pricesDuck';
import rolesReducer from './pages/userPermissions/rolesDuck';
import transactionsReducer from './pages/transactions/transactionsDuck';
import dashboardReducer from './pages/dashboard/dashboardDuck';
import pointsReducer from './pages/points/pointsDuck';
import manufacturersReducer from './pages/manufacturers/manufacturersDuck';
import protocolsReducer from './pages/protocols/protocolsDuck';
import remoteControlReducer from './pages/devices/remoteControl/remoteControlDuck';
import cardsReducer from './pages/cards/cardDuck';
import twintReducer from './pages/twint/twintDuck';
import transactionsChartsReducer from './pages/transactions/transactionChartsDuck';
import providersReducer from './pages/providers/providerDuck';
import providerFieldsReducer from './pages/providerFields/providerFieldsDuck';
import providerTemplatesReducer from './pages/providerTemplates/providerTemplateDuck';
import historiesReducer from './pages/history/historyDuck';
import themeDuck from '../themes/themeDuck';
import formReducer from './common/formControls/formDuck';

// FLEETNET
import cardIssuersReducer from './fleetnet-router/pages/cardIssuer/cardIssuerDuck';
import cardProfilesReducer from './fleetnet-router/pages/CardProfile/cardProfileDuck';
import hostProviderReducer from './fleetnet-router/pages/hostProvider/hostProviderDuck';
import fleetnetSitesReducer from './fleetnet-router/pages/FleetnetSites/fleetnetSiteDuck';
import fleetnetDevicesReducer from './fleetnet-router/pages/FleetnetDevices/fleetnetDeviceDuck';

// PARKING
import tariffsReducer from './pages/parking/tariffs/tariffsDuck';
import siteAssignmentsReducer from './pages/parking/siteAssignments/siteAssignmentsDuck';
import freeParkingConfigurationsReducer from './pages/parking/freeParking/configurationDuck';
import freeParkingConfigurationAssignmentsReducer from './pages/parking/freeParking/siteAssignments/configurationAssignmentsDuck';

// CORE - MARKETPLACE
import marketplaceReducer from './pages/marketplace/marketplaceDuck';
import digitalReceiptsReducer from './pages/digitalReceipts/receiptTemplates/digitalReceiptsDuck';
import emailDeliveryReducer from './pages/digitalReceipts/emaiDelivery/emailDeliveryDuck';
import receiptSiteAssignmentsReducer from './pages/digitalReceipts/siteAssignments/siteAssignmentsDuck';

interface ErrorModel {
    user_message: string;
    internal_message: string;
    code: number | string;
    more_info: string;
}

const excludedActions = [
    'remoteControl/actions/add/fulfilled',
    'remoteControl/actions/update/fulfilled',
    'remoteControl/createTransaction/fulfilled',
    'test-host-configuration/fulfilled',
    'dashboard/layouts/fulfilled',
    'marketplace/module/status/fulfilled',
    'marketplace/module/activation/fulfilled',
];

const excludedRejectedActions = [
    'marketplace/module/status/rejected',
    'marketplace/module/fields/rejected',
];

/**
 * Log a warning and show a toast!
 */

const showMessageToaster = (errorMessage: string, warningMessage?: string) => {
    if (warningMessage) {
        // in case of 403 status
        showToaster(ToasterType.Warning, warningMessage);
        return;
    }
    showToaster(ToasterType.Error, errorMessage);
};

const processErrorCode = (error: ErrorModel) => {
    const { code, user_message } = error;

    if (code === 1) {
        showToaster(ToasterType.Error, user_message, '', true);
        return;
    }
    let translatedError = t(`errorCodes.code${code ?? ''}`);
    if (translatedError?.startsWith('errorCodes.')) {
        translatedError = user_message;
    }
    //another translate is needed here to check if error code is 'known'
    //translation from toaster would be called again and wasted without extra parameter
    showToaster(ToasterType.Error, translatedError, '', true);
};

const setRejectedError = (action: any) => {
    const data = action.payload.data;
    if (data?.message) {
        showToaster(ToasterType.Error, data.message);
        return;
    }
    if (data?.errors && data.errors.length > 0) {
        const pathname = window.location.pathname;
        if (pathname.includes('edit') || pathname.includes('view')) {
            if (action.payload.status === 404) {
                window.history.back();
                return;
            }
        }
        data.errors.forEach((error: ErrorModel) => {
            processErrorCode(error);
        });
        return;
    }
    if (action.type.indexOf('auth') === -1) {
        showMessageToaster(action.payload.message, action.payload.Message);
    }
};

export const rtkQueryErrorLogger: Middleware = () => (next) => (action: any) => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
    if (!action.payload) {
        return next(action);
    }

    if (isRejectedWithValue(action) && excludedRejectedActions.indexOf(action.type) === -1) {
        setRejectedError(action);
    } else if (
        isFulfilled(action) &&
        action.payload.status === 201 &&
        excludedActions.indexOf(action.type) === -1
    ) {
        showToaster(ToasterType.Success, SUCCESSFUL_ACTION, Operations.Created);
    }

    return next(action);
};
const combinedReducer = combineReducers({
    auth: authReducer,
    users: usersReducer,
    mandators: mandatorsReducer,
    modal: modalReducer,
    loading: loadingReducer,
    services: servicesReducer,
    userRights: userRightsReducer,
    sites: sitesReducer,
    devices: devicesReducer,
    products: productsReducer,
    productsAssignment: productsAssignmentReducer,
    prices: pricesReducer,
    roles: rolesReducer,
    transactions: transactionsReducer,
    dashboard: dashboardReducer,
    layout: layoutReducer,
    points: pointsReducer,
    manufacturers: manufacturersReducer,
    protocols: protocolsReducer,
    remoteControl: remoteControlReducer,
    cards: cardsReducer,
    transactionsCharts: transactionsChartsReducer,
    cardIssuers: cardIssuersReducer,
    cardProfiles: cardProfilesReducer,
    tariffs: tariffsReducer,
    assignments: siteAssignmentsReducer,
    freeParkingConfigurations: freeParkingConfigurationsReducer,
    commonData: commonDataReducer,
    providers: providersReducer,
    providerFields: providerFieldsReducer,
    providerTemplates: providerTemplatesReducer,
    payedServices: payedServicesReducer,
    hostProviders: hostProviderReducer,
    histories: historiesReducer,
    theme: themeDuck,
    marketplace: marketplaceReducer,
    digitalReceipts: digitalReceiptsReducer,
    receiptSiteAssignments: receiptSiteAssignmentsReducer,
    emailDelivery: emailDeliveryReducer,
    twintSites: twintReducer,
    fleetnetSites: fleetnetSitesReducer,
    fleetnetDevices: fleetnetDevicesReducer,
    freeParkingConfigurationAssignments: freeParkingConfigurationAssignmentsReducer,
    formData: formReducer,
});

const rootReducer = (state: any, action: Action) => {
    if (action.type.indexOf('logout/fulfilled') > -1) {
        state = undefined;
    }
    return combinedReducer(state, action);
};

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({ serializableCheck: false }).concat(rtkQueryErrorLogger),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
