import { RefObject, useEffect } from 'react';
import { FormikProps } from 'formik';
import { exitEdit } from '../../helpers';

export const useExitEdit = <T extends Record<any, any>>(
    formRef: RefObject<FormikProps<T>>,
    field: string,
    className: string
) => {
    useEffect(() => {
        function editRowEvent(event: MouseEvent) {
            const target = event.target as HTMLElement;
            if (target.classList.contains(className)) {
                formRef.current?.setFieldValue(field, exitEdit(formRef.current?.values[field]));
            }
        }
        window.addEventListener('click', editRowEvent);
        return () => window.removeEventListener('click', editRowEvent);
    }, [className, field, formRef]);
};
