import {
    BASE_URL_DIGITAL_RECEIPT,
    BASE_URL_FLEETNET,
    BASE_URL_FREEPARKING,
    BASE_URL_TWINT,
} from '../../../constants';

export const ITEMS = [
    {
        name: 'FreeParking',
        url: BASE_URL_FREEPARKING,
    },
    {
        name: 'DigitalReceipts',
        url: BASE_URL_DIGITAL_RECEIPT,
    },
    {
        name: 'TWINT',
        url: BASE_URL_TWINT,
    },
    {
        name: 'FleetNet',
        url: BASE_URL_FLEETNET,
    },
];
