// accepted types for a point
export const POINT_TYPES = [
    { name: 'Type1', value: 'Type1' },
    { name: 'Type2', value: 'Type2' },
    { name: 'CCS', value: 'CCS' },
    { name: 'CHAdeMO', value: 'CHAdeMO' },
    { name: 'Tesla', value: 'Tesla' },
    { name: 'Schuko', value: 'Schuko' },
    { name: 'Domestic', value: 'Domestic' },
    { name: 'CEE', value: 'CEE' },
    { name: 'Type1 Combo', value: 'Type1 Combo' },
    { name: 'Type2 Combo', value: 'Type2 Combo' },
    { name: 'Type3A', value: 'Type3A' },
    { name: 'Type3C', value: 'Type3C' },
];

//point statuses
export const POINT_STATUSES = new Map<string, string>([
    ['available', 'points.statuses.available'],
    ['in_use', 'points.statuses.inUse'],
    ['reserved', 'points.statuses.reserved'],
    ['occupied', 'points.statuses.occupied'],
    ['out_of_order', 'points.statuses.outOfOrder'],
    ['unknown', 'points.statuses.unknown'],
]);
